<template>
  <div>
    <input @input="fileAdded($event)" placeholder="Upload profile picture..." class="form-control" type="file" accept="image/*" id="ProfileImage">
    <b-modal centered hide-footer header-class="pb-0 border-bottom-0"
      v-model="cropper.modal" body-class="p-3 p-md-4" id="modal-1" title="Edit Image"
      @hide="resetForm()">
      <div v-if="cropper.modal">
        <vue-image-cropper :imgSrc="cropper.imgSrc"
          @completed="uploadFile($event)" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueImageCropper from '@/components/VueImageCropper.vue';

export default {
    components:{
        VueImageCropper
    },
    props:{
        uploadUrl:{
            type: String,
            default: "/api/uploads"
        },
        fileFormType:{
            type: String,
            default: "user_profile"
        }
    },
    data(){
        return{
            cropper:{
                modal: false,
                imgSrc: "",
                image: ""
            }
        }
    },
    methods:{
        fileAdded($event){
            let file = $event.target.files[0]
            if(file.size > 2097152){
                this.alertError('File size is more than 2MB'); return;
            }
            this.$filters.fileReader(file)
                .then(result => {
                    this.cropper.imgSrc = result.dataURL;
                    this.cropper.modal = true
                })
                .catch(error => {
                    this.alertError('Error reading file '+error);
                });
        },
        uploadFile(file){
            let formData = new FormData();
            formData.append('image_file', file);
            
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(this.absoluteUrl(this.uploadUrl), formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    this.cropper.image = response.data.data
                    this.$emit('uploadSuccess', response.data.data)
                    this.resetForm()
                }
            })
        }, 
        resetForm() {
            this.cropper = { modal: false,  imgSrc: "" }
        },
    }
}
</script>

<style>

</style>